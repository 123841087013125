import React from 'react';

import styles from "./Layout.module.scss";

export function Layout(props) {
  return (
    <div className={styles.main}>
      {props.children}
    </div>
  );
}
import React from 'react';

import styles from "./Home.module.scss";

import LogoImg from "../assets/presentation/images/logo.svg";

export function Home(props) {
  return (
    <div className={styles.main}>
      <img src={LogoImg} alt="logo" />
      <span>
        Mrzí nás to, ale momentálne prebieha plánovaná údržba systému.
      </span>
      <span>
        Ďakujeme za trpezlivosť.
      </span>
    </div>
  );
}